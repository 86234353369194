<template>
  <div class="r-card" id="office-information-card">
    <div class="level mb-0">
      <div class="level-left">
        <h3>Office Information</h3>
      </div>
    </div>

    <div class="columns is-multiline mb-0">
      <div class="column is-12">
        <span>
          <b-icon icon="office-building" size="is-small" />
          Office Name
        </span>
        <p class="settings-personal-info-data">
          {{ officeName }}
        </p>
      </div>
      <div class="column is-12">
        <span>
          <b-icon icon="office-building-marker" size="is-small" />
          Office Location
        </span>
        <p class="settings-personal-info-data">
          {{ officeLocation }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    officeName: String,
    officeLocation: String,
  },
}
</script>
