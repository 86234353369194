<template>
  <div id="personal-info-header-card">
    <div class="columns is-multiline is-mobile">
      <div class="column is-12-mobile">
        <div class="columns is-multiline is-mobile">
          <div
            class="column is-4-mobile is-3-desktop is-1-widescreen profile-picture"
          >
            <ProfilePicture
              :user-id="userId"
              :profile-picture-url="profilePictureUrl"
            />
          </div>
          <div class="column is-4-mobile is-6-desktop is-2-widescreen">
            <p class="settings-personal-info-name">
              {{ fullName }}
            </p>

            <p class="settings-personal-info-employee-id">
              Employee ID {{ employeeId }}
            </p>
          </div>

          <div class="column is-4-mobile is-2-desktop is-1-widescreen">
            <div
              v-if="workingStatus === 'online'"
              class="settings-personal-info-status"
              id="personal-info-status-active"
            >
              Online
            </div>
            <div
              v-else-if="workingStatus === 'offline'"
              class="settings-personal-info-status"
              id="personal-info-status-inactive"
            >
              Offline
            </div>
            <div
              v-else
              class="settings-personal-info-status"
              id="personal-info-status-leave"
            >
              Leave
            </div>
          </div>

          <div class="column is-12-mobile">
            <div class="columns is-multiline is-mobile">
              <div class="column is-4-mobile is-4-desktop">
                <div class="settings-personal-info-role-icon">
                  <b-icon icon="flash-circle" size="is-small" />
                  Role
                </div>
              </div>

              <div class="column is-8-mobile is-6-desktop">
                <p class="settings-personal-info-role">
                  {{ positionName }}
                </p>
              </div>

              <div class="column is-4-mobile is-4-desktop">
                <b-icon icon="calendar-blank" size="is-small" />
                Annual Leave
              </div>

              <div class="column is-8-mobile is-6-desktop">
                <p class="settings-personal-info-last-login">
                  {{ annualLeave }}
                </p>
              </div>
            </div>
          </div>

          <div class="column is-12-mobile">
            <div class="columns is-multiline is-mobile">
              <div class="column is-4-mobile is-4-desktop">
                <div class="settings-personal-info-role-icon">
                  <b-icon icon="flash-circle" size="is-small" />
                  Timezone
                </div>
              </div>

              <div class="column is-8-mobile is-6-desktop">
                <p class="settings-personal-info-role">
                  {{
                    timezone != null
                      ? timezone
                      : getDefaultTimezone() + '(not set)'
                  }}
                  <b-button
                    size="is-small"
                    class="is-command pb-4"
                    @click="
                      $router.push('/settings/personal-info/edit-timezone')
                    "
                    icon-left="pencil"
                    v-if="isSelf"
                  >
                    Edit
                  </b-button>
                </p>
              </div>

              <div class="column is-4-mobile is-4-desktop" v-if="isSelf">
                <div class="settings-personal-info-role-icon">
                  <b-icon icon="account-supervisor-circle" size="is-small" />
                  Reporting to
                </div>
              </div>

              <div class="column is-8-mobile is-6-desktop" v-if="isSelf">
                <p class="settings-personal-info-role">
                  {{ reportingTo }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProfilePicture from '../views/Settings/PersonalInfo/ProfilePicture'
import moment from 'moment-timezone'
export default {
  components: {
    ProfilePicture,
  },
  props: {
    userId: Number,
    fullName: String,
    workingStatus: String,
    profilePictureUrl: String,
    positionName: String,
    employeeId: String,
    leaveInfo: String,
    timezone: String,
    annualLeave: String,
    reportingTo: String,
    isSelf: Boolean,
  },
  methods: {
    getDefaultTimezone() {
      return moment.tz.guess()
    },
  },
}
</script>

<style scoped></style>
