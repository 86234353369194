<template #page-content>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Personal Info</h2>
      <r-third-level-menu />
    </template>
    <template #page-content>
      <div id="personal-information">
        <div v-if="!isLoadingCurrentUser && currentUser != null">
          <EmployeeInfoHeader
            :user-id="currentUser.id"
            :profile-picture-url="formatProfilePictureUrl(currentUser)"
            :employee-id="formatEmployeeId(currentUser)"
            :full-name="currentUser.fullName"
            :position-name="formatPositionName(currentUser)"
            :working-status="currentUser.workingStatus"
            :timezone="currentUser.timezone"
            :annualLeave="formatAnnualLeave(currentUser)"
            :reporting-to="formatReportingTo(currentUser)"
            :is-self="true"
          />

          <div class="columns is-multiline">
            <div class="column is-6-desktop is-6-tablet is-12-mobile">
              <GeneralInformation
                :user-id="currentUser.id"
                :birth-day="formatBirthday(currentUser)"
                :address="formatAddress(currentUser)"
                :gender="formatGender(currentUser)"
                :contact-number="formatPhoneNumber(currentUser)"
                :personal-email="formatPersonalEmail(currentUser)"
                :office-email="formatOfficeEmail(currentUser)"
                :religion="formatReligion(currentUser)"
                :blood-type="formatBloodType(currentUser)"
                editable
              />
            </div>
            <div class="column is-6-desktop is-6-tablet is-12-mobile">
              <OfficeInformation
                :user-id="currentUser.id"
                :office-name="formatOfficeName()"
                :office-location="formatOfficeLocation()"
              />
            </div>
          </div>
        </div>
        <div v-else>Loading</div>
      </div>
    </template>
  </r-page>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import moment from 'moment'
import { showToast } from '../../../services/util'
import EmployeeInfoHeader from '../../../components/EmployeeInfoHeader'
import GeneralInformation from '../../../components/GeneralInformation'
import OfficeInformation from '../../../components/OfficeInformation'
import ApiService from '@/services/common/api.service'

export default {
  components: { GeneralInformation, EmployeeInfoHeader, OfficeInformation },
  // components: {},
  computed: {
    ...mapGetters({
      currentUser: 'user/getUserInfo',
      isLoadingCurrentUser: 'user/getIsLoading',
      uploadImageProgress: 'user/getUploadImageProgress',
    }),
  },
  data() {
    return {
      key: 1,
      isModalChangePictureOpen: false,
      imageToBeReplaced: null,
      file: null,
      locations: null,
    }
  },
  async mounted() {
    this.getLocations()
  },
  methods: {
    ...mapActions({
      changeProfileImage: 'user/changeProfileImage',
    }),
    ...mapMutations({
      setProfilePictureUrl: 'user/setProfilePictureUrl',
    }),

    /**
     * Reset to original image
     */
    removePickedImage() {
      this.imageToBeReplaced = this.formatProfilePictureUrl(this.currentUser)
      this.file = null
    },

    /**
     * Get Image
     * @param {Object} e - for change status filter
     */
    getImage(e) {
      this.file = e

      //*validate size here. Size is in Byte. maximum 2MB
      if (this.file.size > 2048000) {
        this.$buefy.dialog.alert({
          title: 'Error',
          message: 'File size maximum is 2 MB',
          type: 'is-danger',
          hasIcon: true,
          icon: 'times-circle',
          iconPack: 'fa',
          ariaRole: 'alertdialog',
          ariaModal: true,
        })
      } else {
        let reader = new FileReader()
        reader.readAsDataURL(this.file)
        reader.onload = (e) => {
          this.imageToBeReplaced = e.target.result
        }
      }
    },

    /**
     * Handle upload frofile image
     */
    async uploadProfileImage() {
      if (this.file == null) {
        showToast('Please pick an image', 'is-danger', 'is-top')
        return
      }
      try {
        let form = new FormData()
        form.append('user_id', this.currentUser.id)
        form.append('image', this.file)
        await this.changeProfileImage(form)
      } catch (err) {
        this.file = null
        showToast(err.response.data.message, 'is-danger', 'is-top')
      }
      this.closeModalChangeProfilePicture()
    },

    /**
     * Handle open modal upload picture
     */
    openModalChangePicture() {
      this.imageToBeReplaced = this.formatProfilePictureUrl(this.currentUser)
      this.isModalChangePictureOpen = true
    },

    /**
     * Handle close modal upload picture
     */
    closeModalChangeProfilePicture() {
      this.file = null
      this.isModalChangePictureOpen = false
    },

    /**
     * Format phone number
     * @param {Object} user - user data
     * @return {String} - formatted phone number / -
     */
    formatPhoneNumber(user) {
      if (user?.personalPhone) {
        return user?.personalPhone
      }
      return '-'
    },

    /**
     * Format Email
     * @param {Object} user - user data
     * @return {String} - formatted email user / -
     */
    formatPersonalEmail(user) {
      if (user?.personalEmail) {
        return user?.personalEmail
      }
      return '-'
    },

    /**
     * Format Work Email
     * @param {Object} user - user data
     * @return {String} - formatted work email user / -
     */
    formatOfficeEmail(user) {
      if (user?.workEmail) {
        return user?.workEmail
      }
      return '-'
    },

    /**
     * Format blood type user
     * @param {Object} user - user data
     * @return {String} - formatted user blood type / -
     */
    formatBloodType(user) {
      if (user?.bloodType) {
        return user?.bloodType
      }
      return '-'
    },

    /**
     * Format user religion
     * @param {Object} user - user data
     * @return {String} - formatted user religion / -
     */
    formatReligion(user) {
      if (user?.religion) {
        return user?.religion
      }
      return '-'
    },

    /**
     * Format user reporting to
     * @param {Object} user - user data
     * @return {String} - formatted user reporting to / -
     */
    formatReportingTo(user) {
      if (user?.employment?.reportingTo) {
        return `${user?.employment?.reportingTo?.firstName || ''} ${
          user?.employment?.reportingTo?.middleName || ''
        } ${user?.employment?.reportingTo?.lastName || ''}`
      }
      return '-'
    },

    /**
     * Format user reporting to
     * @param {Object} user - user data
     * @return {String} - formatted user position name / -
     */
    formatPositionName(user) {
      if (user?.position) {
        return user?.position.positionName
      }
      return '-'
    },

    /**
     * Format user reporting to
     * @param {Object} user - user data
     * @return {String} - formatted user birthday / -
     */
    formatBirthday(user) {
      if (user?.placeOfBirth) {
        if (user?.dateOfBirth) {
          return user?.placeOfBirth + ', ' + this.parseDate(user?.dateOfBirth)
        }
        return user?.placeOfBirth
      }
      return '-'
    },

    /**
     * Format user annual leave
     * @param {Object} user - user data
     * @return {String} - formatted user reporting to/ -
     */
    formatAnnualLeave(user) {
      let annualLeave = '-'
      let remaining = '-'
      let positionType = '-'
      if (user?.employment?.annualLeave) {
        annualLeave = user?.employment?.annualLeave
      }
      if (user?.employment?.remainingAnnualLeave) {
        remaining = user?.employment?.remainingAnnualLeave
      }
      if (user?.position?.type) {
        positionType =
          parseInt(user?.position?.type) === 2
            ? '+1 Every Month'
            : 'Fixed Time Off'
      }
      return `${remaining}/${annualLeave} (${positionType})`
    },

    /**
     * Format date
     * @param {Date} timeStamp - time stamp
     * @return {String} - formatted date
     */
    parseDate(timeStamp) {
      return moment(timeStamp).format('DD MMMM YYYY')
    },

    /**
     * Format date
     * @param {Date} timeStamp - time stamp
     * @return {String} - formatted date
     */
    parseDateTime(timeStamp) {
      return moment(timeStamp).format('DD MMM YYYY, hh:mm')
    },

    /**
     * Format gender
     * @param {Object} user - user data
     * @return {String} - formatted string (Male/Female)
     */
    formatGender(user) {
      switch (user?.gender) {
        case 'F':
          return 'Female'
        case 'M':
          return 'Male'

        default:
          return '-'
      }
    },

    /**
     * Format user profile picture by gender
     * @param {Object} user - user data
     * @return {String} - path image url by gender
     */
    formatProfilePictureUrl(user) {
      if (user?.profilePictureUrl) {
        return user?.profilePictureUrl
      }

      if (user?.gender === 'M') {
        return '/images/default-profile-picture-male.png'
      }

      if (user?.gender === 'F') {
        return '/images/default-profile-picture-female.png'
      }

      return '/images/default-profile-picture.png'
    },

    /**
     * Format employee id
     * @param {Object} user - user data
     * @return {String} - formatted employee id
     */
    formatEmployeeId(user) {
      if (user?.employment?.nik) {
        return user?.employment?.nik
      }
      return '-'
    },

    /**
     * Format address
     * @param {Object} user - time stamp
     * @return {String} - formatted user address
     */
    formatAddress(user) {
      let address = '-'

      if (user?.address.streetName) {
        address = user?.address.streetName
      }
      if (user?.address.district) {
        address += ', ' + user?.address.district
      }
      if (user?.address.village) {
        address += ', ' + user?.address.village
      }
      if (user?.address.city) {
        address += ', ' + user?.address.city
      }
      if (user?.address.province) {
        address += ', ' + user?.address.province
      }
      return address
    },
    getLocations() {
      ApiService.get('/api/space-roketin/location').then((response) => {
        this.locations = response.data
      })
    },
    getOfficeLocation() {
      return this.locations.filter(
        (item) => item.id === this.currentUser.employment.locationId
      )
    },
    formatOfficeName() {
      let officeName = this.getOfficeLocation()[0].locationName
      if (officeName) return officeName
      return '-'
    },
    formatOfficeLocation() {
      let OfficeLocation = '-'
      if (!this.currentUser?.employment?.locationId) return OfficeLocation
      let Office = this.getOfficeLocation()
      if (Office[0]?.address) {
        OfficeLocation = Office[0].address
      }
      if (Office[0]?.city) {
        OfficeLocation += ', ' + Office[0].city
      }
      if (Office[0]?.country) {
        OfficeLocation += ', ' + Office[0].country
      }
      return OfficeLocation
    },
  },
}
</script>
